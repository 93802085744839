<template>
    <BaseContainer>
        <span slot="principal">
            <v-main class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Campanhas</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        v-show="$vuetify.breakpoint.mdAndUp"
                        @keypress.enter="consultar"
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <PeriodoFilter
                        v-model="periodo"
                        @update="consultar"
                    />
                    <v-spacer/>
                    <ButtonAddNew @click="openCadastro" />
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 210"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :search="search"
                            :options.sync="options"
                        >
                            <template v-slot:item.programacao="{item}">
                                <div v-if="item.tipo === 'automatico'"> 
                                    <div>
                                        <v-chip
                                            outlined
                                            x-small
                                        >
                                            <v-icon small :color="getDayColor(item.programacao.dias, 1)">mdi-alpha-s</v-icon>
                                            <v-icon small :color="getDayColor(item.programacao.dias, 2)">mdi-alpha-t</v-icon>
                                            <v-icon small :color="getDayColor(item.programacao.dias, 3)">mdi-alpha-q</v-icon>
                                            <v-icon small :color="getDayColor(item.programacao.dias, 4)">mdi-alpha-q</v-icon>
                                            <v-icon small :color="getDayColor(item.programacao.dias, 5)">mdi-alpha-s</v-icon>
                                            <v-icon small :color="getDayColor(item.programacao.dias, 6)">mdi-alpha-s</v-icon>
                                            <v-icon small :color="getDayColor(item.programacao.dias, 7)">mdi-alpha-d</v-icon>
                                        </v-chip>
                                    </div>
                                    <div>
                                        <v-chip
                                            class="primary--text"
                                            outlined
                                            x-small
                                        >
                                        {{ item.programacao.start }} - {{ item.programacao.end }}
                                        </v-chip>
                                    </div>
                                </div>
                                <div v-else>
                                    <v-chip
                                        class="primary--text"
                                        outlined
                                        small
                                    >
                                        {{ item.tipo }}
                                    </v-chip>
                                </div>
                            </template>
                            <template v-slot:item.status="{ item }">
                                <v-chip
                                    v-if="item.status"
                                    class="white--text"
                                    :color="getStatusColor(item.status)"
                                >
                                    {{ item.status }}
                                </v-chip>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                {{ formatDate(item.created_at) }}
                            </template>
                            <template v-slot:item.conversao="{ item }">
                                {{ formatPrice(+item.envios ? (item.pedidos / item.envios) * 100 : 0) }}%
                            </template>
                            <template v-slot:item.total="{ item }">
                                <span style="color: green; font-weight: 600;">
                                    R${{ formatPrice(item.total) }}
                                </span>
                            </template>
                            <template v-slot:item.delete="{ item }">
                                <v-btn icon @click.stop="excluir(item.id)" color="error">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <Paginate
                            v-model="pagination"
                            @input="consultar"
                        />
                    </v-card-text>
                </v-card>
            </v-main>
            <CadastroCampanha
                :visible="visible"
                :formData="form"
                @close="close"
            />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ButtonAddNew from '@/components/ButtonAddNew';
import PeriodoFilter from '@/components/PeriodoFilter';
import CadastroCampanha from '@/pages/cadastro/Campanha';
import Paginate from '@/components/Paginate';

export default {
    name: 'Campanhas',

    components: {
        BaseContainer,
        ButtonAddNew,
        CadastroCampanha,
        PeriodoFilter,
        Paginate,
    },

    data: () => ({
        visible: false,
        search: '',
        loading: true,
        dados: [],
        periodo: {},
        form: null,
        headers: [
            { text: 'Código', value: 'id' },
            { text: 'Nome', value: 'nome' },
            { text: 'Programação', value: 'programacao' },
            { text: 'Status', value: 'status' },
            { text: 'Público', value: 'publico.nome' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Envios', value: 'envios' },
            { text: 'Conversão', value: 'conversao' },
            { text: 'Pedidos', value: 'pedidos' },
            { text: 'Total Vendas', value: 'total' },
            { text: 'Excluir', value: 'delete', sortable: false, align: 'end'},
        ],
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        options: {
            handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        consultar() {
            const params = this.getParams();

            this.loading = true;
            this.dados = [];
            this.$http.get('campanhas?' + params).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },

        getParams() {
            const params = new URLSearchParams();

            params.append('start', this.periodo.start);
            params.append('end', this.periodo.end);

            // params.append('q', this.search || '');
            params.append('page', this.pagination.current_page);

            if (this.options.sortBy.length) {
                params.append('sortField', this.options.sortBy[0]);
            }
            if (this.options.sortDesc.length) {
                params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            }

            return params.toString();
        },

        close() {
            this.visible = false;
            this.consultar();
        },

        openCadastro() {
            this.visible = true;
        },

        editar(row) {
            this.form = row;
            this.visible = true;
        },

        async excluir(id) {
            if (await this.$root.$confirm('Confirmação', 'Deseja realmente excluir?', { color: 'red darken-1' })) {
                this.$http.delete(`campanhas/${id}`).then(() => {
                    this.notify('Excluído com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                });
            }
        },

        getStatusColor(status) {
            if (status === 'pausado') return 'amber accent-3';
            if (status === 'cancelado') return 'red';
            if (status === 'aguardando') return 'blue';
            if (status === 'executando') return 'green';
            if (status === 'finalizado') return 'blue';
        },

        getDayColor(list, day) {
            return list?.includes(day) ? 'primary' : 'red';
        },
    },
}
</script>
